import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/log-records";
const STORE_NAME = "logs";

export const fetchAllVehicleRunning = async (
  page,
  limit,
  startDate,
  endDate,
  vehicleUuids,
  staffUuids,
  projectUuids,
  reviewStatus,
  editStatus
) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/vehicle-running-logs/all?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&vehicle_uuids=${vehicleUuids}&staff_uuids=${staffUuids}&project_uuids=${projectUuids}&review_status=${reviewStatus}&edit_status=${editStatus}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    return [];
  }
};

export const getVehicleRunning = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-running-logs/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot fetch logs offline.`,
    });
  }
};

export const fetchAllVehicleFuel = async (
  page,
  limit,
  startDate,
  endDate,
  vehicleUuids,
  staffUuids
) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/vehicle-fuel-history/all?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&vehicle_uuids=${vehicleUuids}&staff_uuids=${staffUuids}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    return [];
  }
};

export const getVehicleFuel = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-fuel-history/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot fetch logs offline.`,
    });
  }
};

export const fetchAllVehicleRepairs = async (
  page,
  limit,
  startDate,
  endDate,
  vehicleUuids,
  staffUuids
) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/vehicle-repair-history/all?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&vehicle_uuids=${vehicleUuids}&staff_uuids=${staffUuids}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    return [];
  }
};

export const getVehicleRepair = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-repair-history/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot fetch logs offline.`,
    });
  }
};
